// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

// third party
import { useTimer } from 'react-timer-hook';

import { gridSpacing } from 'store/constant';

// assets
import imageGrid from 'assets/images/maintenance/img-soon-grid.svg';
import imageDarkGrid from 'assets/images/maintenance/img-soon-grid-dark.svg';
import imageBlock from 'assets/images/maintenance/img-soon-block.svg';
import imageBlueBlock from 'assets/images/maintenance/img-soon-blue-block.svg';
import imagePurpleBlock from 'assets/images/maintenance/img-soon-purple-block.svg';

// styles
const CardMediaWrapper = styled('div')({
    maxWidth: 720,
    margin: '0 auto',
    position: 'relative'
});

const PageContentWrapper = styled('div')({
    maxWidth: 450,
    margin: '0 auto',
    textAlign: 'center'
});

const TimerWrapper = styled('div')({
    maxWidth: 450,
    margin: '0 auto'
});

const ComingSoonCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const TimeBlock = styled('div')(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.secondary.main,
    borderRadius: '12px',
    padding: '24px 0',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '3rem'
}));

const CardMediaBlock = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '8s blink ease-in-out infinite'
});

const CardMediaBlue = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '15s wings ease-in-out infinite'
});

const CardMediaPurple = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '12s wings ease-in-out infinite'
});

// ===========================|| COMING SOON 2 ||=========================== //

const Privacy = () => {
    const theme = useTheme();
    const time = new Date();
    time.setSeconds(time.getSeconds() + 3600 * 24 * 2 - 3600 * 15.5);

    const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp: time });

    return (
        <ComingSoonCard>
            <CardContent>
                <Grid container justifyContent="center" spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <PageContentWrapper>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography variant="h1">Terms of Service</Typography>
                                </Grid>
                            </Grid>
                        </PageContentWrapper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <section>
                            <h2>1. Acceptance of Terms</h2>
                            <p>
                                By accessing or using SurveyFlip.com (&quote;the website&quote; or &quote;the service&quote;), you agree to
                                comply with and be bound by the following terms and conditions of use. If you do not agree to these terms,
                                please do not use the service.
                            </p>
                        </section>

                        <section>
                            <h2>2. Use of the Service</h2>
                            <p>
                                You agree to use the service for lawful purposes and in a way that does not infringe on the rights of others
                                or restrict their use and enjoyment of the service. Prohibited activities include but are not limited to:
                            </p>
                            <ul>
                                <li>Unauthorized access to our systems or data.</li>
                                <li>Interference with the proper working of the service.</li>
                                <li>Use of the service to engage in any form of harassment or illegal activity.</li>
                            </ul>
                        </section>

                        <section>
                            <h2>3. User Accounts</h2>
                            <p>
                                Users may be required to create an account to access certain features of the service. You are responsible
                                for maintaining the confidentiality of your account information and agree to accept responsibility for all
                                activities that occur under your account.
                            </p>
                        </section>

                        <section>
                            <h2>4. Intellectual Property</h2>
                            <p>
                                The content, trademarks, and intellectual property on SurveyFlip.com are owned by or licensed to us and are
                                subject to copyright and other intellectual property rights under the law. You may not reproduce,
                                distribute, display, or create derivative works of any content without our prior written permission.
                            </p>
                        </section>

                        <section>
                            <h2>5. Disclaimer of Warranties</h2>
                            <p>
                                The service is provided &quote;as is&quote; and &quote;as available&quote; without any warranties, expressed
                                or implied. We do not guarantee the accuracy, completeness, or suitability of the service for any particular
                                purpose. Your use of the service is at your own risk.
                            </p>
                        </section>

                        <section>
                            <h2>6. Changes to Terms</h2>
                            <p>
                                We reserve the right to modify these terms at any time. Changes will be effective immediately upon posting
                                on the website. Continued use of the service after changes constitute your acceptance of the revised terms.
                            </p>
                        </section>

                        <section>
                            <h2>7. Governing Law</h2>
                            <p>
                                These terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes
                                arising from or in connection with these terms are subject to the exclusive jurisdiction of the courts in
                                [Your Jurisdiction].
                            </p>
                        </section>

                        <footer>
                            <p>
                                Thank you for using SurveyFlip.com. If you have any questions or concerns, please contact us at{' '}
                                <a href="mailto:contact@surveyflip.com">contact@surveyflip.com</a>.
                            </p>
                        </footer>
                    </Grid>
                </Grid>
            </CardContent>
        </ComingSoonCard>
    );
};

export default Privacy;
