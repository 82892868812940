import create from 'zustand';

const useQuestionStore = create((set) => ({
    activeStep: 0,
    drawerOpen: false,
    currentSurveyAllQuestion: [],
    selectedQuestionTypeIdentifier: null,
    selectedQuestionTypeId: null,
    questionData: {
        question_title: '',
        question_info: '',
        is_active: true,
        required: true,
        meta: {}
    },
    SET_SURVEY_QUESTIONS: (questions) =>
        set((state) => {
            state.currentSurveyAllQuestion = questions;
        }),
    RESET_QUESTION_STATE: () =>
        set((state) => {
            state.selectedQuestionTypeId = null;
            state.selectedQuestionTypeIdentifier = null;
            state.questionData = {
                question_title: '',
                question_info: '',
                is_active: true,
                required: true,
                meta: {}
            };
        }),

    RESET_QUESTION_DATA: () =>
        set((state) => {
            state.questionData = {
                question_title: '',
                question_info: '',
                is_active: true,
                required: true,
                meta: {}
            };
        }),
    SET_SELECTED_QUESTION_ID: (id) =>
        set((state) => {
            state.selectedQuestionTypeId = id;
        }),
    SET_SELECTED_QUESTION_TYPE_ID: (id) =>
        set((state) => {
            state.selectedQuestionTypeId = id;
        }),
    SET_SELECTED_QUESTION_TYPE_IDENTIFIER: (identifier) =>
        set((state) => {
            state.selectedQuestionTypeIdentifier = identifier;
        }),
    SET_ACTIVE_STEP: (step) =>
        set((state) => {
            state.activeStep = step;
        }),
    INCREASE_STEP: () =>
        set((state) => {
            state.activeStep += 1;
        }),
    DECREASE_STEP: () =>
        set((state) => {
            state.activeStep = state.activeStep !== 0 ? state.activeStep - 1 : 0;
        }),
    RESET_STEP: () =>
        set((state) => {
            state.activeStep = 0;
        }),
    TOGGLE_COMMON_EXTRA_OPTION: (name) =>
        set((state) => {
            state.questionData[name] = !state.questionData[name];
        }),
    FORM_CHANGE_HANDLER: (name, value) =>
        set((state) => {
            // if(state.question_data[name] instanceof Object && !(state.question_data[name] instanceof Array)){
            //     state.questionData[name]  = value;
            // } else if(state.question_data[name] instanceof Object && state.question_data[name] instanceof Array){
            //     state.questionData[name].push(value);
            // } else {
            state.questionData[name] = value;
            // }
        }),
    FORM_META_CHANGE_HANDLER: (name, value) =>
        set((state) => {
            // if(state.question_data[name] instanceof Object && !(state.question_data[name] instanceof Array)){
            //     state.questionData[name]  = value;
            // } else if(state.question_data[name] instanceof Object && state.question_data[name] instanceof Array){
            //     state.questionData[name].push(value);
            // } else {
            state.questionData.meta[name] = value;
            // }
        })
}));

export default useQuestionStore;
