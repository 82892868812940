import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { Typography } from '@mui/material';
import { range } from 'lodash';

const ListSkeleton = ({ times, marginTop }) => {
    const dumb = 'dd';
    return (
        <div style={{ marginTop }}>
            <Typography component="div">
                {range(0, times).map((i) => (
                    <Skeleton key={i} animation="wave" height={60} style={{ marginLeft: 20, marginRight: 20 }} />
                ))}
            </Typography>
        </div>
    );
};
ListSkeleton.propTypes = {
    times: PropTypes.number.isRequired,
    marginTop: PropTypes.number
};

export default ListSkeleton;
