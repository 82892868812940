/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Grid, Button, Box, Typography } from '@mui/material';
import { useFormik } from 'formik';
import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';

const useStyles = makeStyles(() => ({
    answer: { width: '100%', height: '100%', marginTop: '10px' },
    title: { marginBotton: '50px' },
    activeColor: { color: 'white' },
    bGroundColor: { backgroundColor: 'rgba(63, 63, 63, 0.8)' },
    customTextField: {
        '& input::placeholder': {
            fontSize: '24px'
        }
    },
    error: {
        fontSize: '0.75rem',
        fontWeight: 400,
        fontFamily: 'Roboto,sans-serif',
        lineHeight: 1.66,
        textAlign: 'center',
        marginTop: '3px',
        marginRight: '14px',
        marginBottom: 0,
        marginLeft: '14px',
        color: '#f44336'
    }
}));

const MessageQuestion = ({ questionData, formik, handleWelcomePageButton, handleBackOnScreen, handleForwardButton }) => {
    const classes = useStyles();
    const isLastQuestion = useMasterSurveyResponseStore((state) => state.isLastQuestion);
    return (
        <>
            <Grid
                container
                style={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    flexDirection: questionData?.meta?.layout_direction === 'ltr' ? 'row-reverse' : 'row',
                    justifyContent: 'flex-end'
                }}
                spacing={2}
            >
                <Grid item sm={6} style={{ paddingTop: '0px', paddingLeft: '0px' }}>
                    <Box
                        component="img"
                        sx={{
                            display: 'block',
                            minHeight: '100%',
                            margin: '0px auto',
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain'
                            // objectPosition: '50% 50%'
                            // maxHeight: { xs: 233, md: 167 },
                            // maxWidth: { xs: 350, md: 250 }
                        }}
                        alt="Placeholder image"
                        src={
                            questionData?.meta?.sectionImageUrl && questionData?.meta?.sectionImageUrl !== ''
                                ? questionData?.meta?.sectionImageUrl
                                : 'https://i0.wp.com/roadmap-tech.com/wp-content/uploads/2019/04/placeholder-image.jpg?resize=1200%2C900&ssl=1'
                        }
                    />
                </Grid>
                <Grid
                    item
                    sm={6}
                    sx={{
                        position: 'relative',
                        paddingBlock: '0px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        overflow: 'auto',
                        alignSelf: 'center',
                        padding: questionData?.meta?.layout_direction === 'ltr' ? '0 0 0 15px' : '0 15px 0 0', // ch
                        alignItems: questionData?.meta?.layout_direction === 'ltr' ? 'end' : 'start' // ch
                    }}
                >
                    <Typography variant="h1" style={{ padding: '10px', marginBottom: '0px', textAlign: 'left' }}>
                        <span dangerouslySetInnerHTML={{ __html: questionData?.question_title }} />
                    </Typography>
                    <Typography style={{ marginTop: '2px', padding: '10px', fontWeight: 'normal', lineHeight: '26px', textAlign: 'left' }}>
                        <span dangerouslySetInnerHTML={{ __html: questionData?.question_info }} />
                    </Typography>

                    <br />
                    {questionData?.meta?.welcome_screen ? (
                        <Button
                            variant="contained"
                            color="info"
                            sx={{ margin: questionData?.meta?.layout_direction === 'ltr' ? '0 10px 0 0px' : '0 0 0 10px' }}
                            onClick={() => handleWelcomePageButton(questionData.id)}
                        >
                            <span dangerouslySetInnerHTML={{ __html: questionData?.meta?.button_text }} />
                        </Button>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                marginTop: 50,
                                marginLeft: 19,
                                // justifyContent: 'space-between'
                                justifyContent: 'left'
                            }}
                        >
                            <Button
                                variant="contained"
                                // disabled={!hasPrevious(currentQuestion)}
                                type="button"
                                onClick={() => handleBackOnScreen(questionData.id)}
                                sx={{ marginRight: '20px' }}
                            >
                                <IconArrowNarrowLeft />
                            </Button>
                            <Button type="submit" color="primary" variant="contained" onClick={() => handleForwardButton(questionData.id)}>
                                {isLastQuestion(questionData) ? 'Submit' : <IconArrowNarrowRight />}
                            </Button>
                        </div>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

MessageQuestion.propTypes = {
    questionData: PropTypes.object,
    formik: PropTypes.object,
    handleWelcomePageButton: PropTypes.func,
    handleBackOnScreen: PropTypes.func,
    handleForwardButton: PropTypes.func
};

export default MessageQuestion;
