import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import useProfileStore from 'zustand_store/useProfileStore';

// ===========================|| AUTH GUARD ||=========================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const ProfileSurveyGuard = ({ children }) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const hasProfileSurvey = useProfileStore((state) => state.completedProfileSurvey);

    useEffect(() => {
        if (!hasProfileSurvey) {
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasProfileSurvey]);

    return children;
};

ProfileSurveyGuard.propTypes = {
    children: PropTypes.node
};

export default ProfileSurveyGuard;
