import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// material-ui
import { makeStyles } from '@mui/styles';
import { Alert, Box, Card, CardContent, Grid, Tab, Tabs, Typography } from '@mui/material';

// project imports
import GeneralSettingsService from 'services/profile.service';
// assets
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import LibraryBooksTwoToneIcon from '@mui/icons-material/LibraryBooksTwoTone';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import { useSnackbar } from 'notistack';

// ===========================|| PROFILE 1 ||=========================== //

const UpdateRole = () => {
    const navigate = useNavigate();
    const { role } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const handleRoleUpdate = async () => {
        const response = await GeneralSettingsService.updateRole(role);
        console.log('Rafid', localStorage.getItem('serviceToken'));
        const serviceToken = localStorage.getItem('serviceToken');
        const user = response.data;

        if (user?.user_role && user?.user_role?.length > 0) {
            if (user?.user_role.includes('Respondent')) {
                window.location.href = process.env.REACT_APP_RESPONDENT_URL;
            } else {
                window.location.href = `${process.env.REACT_APP_CLIENT_URL}/sso?token=${serviceToken}`;
            }
        } else {
            window.location.href = '/';
        }
    };

    useEffect(() => {
        handleRoleUpdate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <h1>Please wait for a while...</h1>
        </>
    );
};

export default UpdateRole;
