import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import MultiStepFormNavigation from './MultiStepFormNavigation';
import PropTypes from 'prop-types';

const MultiStepForm = ({ children, onSubmit, currentQuestion, fetchNextQuestion, fetchPrevQuestion, multiStepFormEl }) => {
    const [stepNumber, setStepNumber] = useState(0);
    const steps = React.Children.toArray(children);
    const step = steps[stepNumber];
    const [snapshot, setSnapshot] = useState(step?.props.initialValues);
    const totalSteps = steps.length;
    const isLastQuestion = useMasterSurveyResponseStore((state) => state.isLastQuestion);

    const questionTemplateRenderHandler = (template, extraInfo) => React.cloneElement(template, extraInfo);

    // eslint-disable-next-line consistent-return
    const handleSubmit = async (values, actions) => {
        console.log(values);

        if (step.props.onSubmit) {
            await step.props.onSubmit(values);
        }

        if (isLastQuestion(currentQuestion)) {
            return onSubmit(values, actions);
            // eslint-disable-next-line no-else-return
        } else {
            actions.setTouched({});
            fetchNextQuestion(currentQuestion.id);
        }
    };

    const onSubmitHandler = () => {
        console.log(step);
    };

    return (
        <>
            <Formik
                initialValues={step?.props.initialValues}
                onSubmit={isLastQuestion(currentQuestion) ? onSubmit : step?.props.onSubmit}
                enableReinitialize
                validateOnChange={false} // this one
                validateOnBlur={false} // and this one
                validationSchema={step?.props.validationSchema}
            >
                {(formik) => (
                    <Form>
                        {questionTemplateRenderHandler(step, { formik })}
                        <MultiStepFormNavigation
                            currentQuestion={currentQuestion}
                            onBackClick={() => fetchPrevQuestion(currentQuestion.id, formik)}
                            elementRef={multiStepFormEl}
                        />
                    </Form>
                )}
            </Formik>
        </>
    );
};

MultiStepForm.propTypes = {
    children: PropTypes.element,
    onSubmit: PropTypes.func,
    currentQuestion: PropTypes.object,
    fetchNextQuestion: PropTypes.func,
    fetchPrevQuestion: PropTypes.func,
    multiStepFormEl: PropTypes.any
};

export default MultiStepForm;
