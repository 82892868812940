/* eslint-disable */
class AssignmentOperatorComparer {
    constructor(value, answer) {
        this.value = value;
        this.answer = answer;
    }

    equal() {
        return this.value === this.answer;
    }

    is() {
        return this.value === this.answer;
    }

    is_not() {
        return this.value !== this.answer;
    }

    starts_with() {
        return this.answer.startsWith(this.value);
    }

    does_not_starts_with() {
        return !this.answer.startsWith(this.value);;
    }

    contains() {
        return this.answer.includes(this.value);
    }

    does_not_contains() {
        return !this.answer.includes(this.value);
    }

    greater_than() {
        return this.answer > this.value;
    }

    lesser_than() {
        return this.answer < this.value;
    }

    compare(value, answer, assignment_operator) {
        this.value = value;
        this.answer = answer;
		assignment_operator = assignment_operator.replace(' ', '_').toLowerCase();

        // Execute the assignment operator method
        return this[assignment_operator]();
    }
}
export default AssignmentOperatorComparer;