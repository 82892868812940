/**
 * axios setup to use mock service
 */

import axios from 'axios';
import SnackbarUtils from './SnackbarUtils';

const axiosServices = axios.create();

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 422) {
            SnackbarUtils.error(error.response?.data?.msg);
        }
        //  If the app is in maintenance mode
        if (error.response?.status === 503 && error.response?.data?.maintenance) {
            window.location.href = '/maintenance';
        }
        if (error.response.data.redirect) {
            // eslint-disable-next-line no-restricted-globals
            location.replace('/not-found');
        }
        return Promise.reject((error.response && error.response.data) || 'Wrong Services');
    }
);

export default axiosServices;
