/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';

class GeneralSettingsService {
    async update(user) {
        const response = await axiosServices.put('profile/update', user);
        return response;
    }

    async get() {
        const response = await axiosServices.get('profile');
        return response;
    }

    async changePassword(data) {
        const response = await axiosServices.post('change-password', data);
        return response;
    }

    async getProfileSurvey() {
        const response = await axiosServices.get('get-profile-survey');
        return response;
    }

    async updateRole(role) {
        const response = await axiosServices.get(`update-role/${role}`);
        return response;
    }
}

export default new GeneralSettingsService();
