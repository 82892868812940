/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';

class ResponseService {
    async trackResponseOnCache(data) {
        const response = await axiosServices.post('answers/tracks', data);
        return response;
    }
}

export default new ResponseService();
