// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrandChrome, IconChartCandle, IconCreditCard, IconCurrencyDollar, IconDashboard, IconUser } from '@tabler/icons';

// constant
const icons = { IconBrandChrome };

// ===========================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||=========================== //

const other = {
    id: 'respondent-other-menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Dashboard" />,
            type: 'item',
            url: '/',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'surveys',
            title: <FormattedMessage id="Surveys" />,
            type: 'item',
            url: '/surveys',
            icon: IconChartCandle,
            breadcrumbs: false,
            disableWithoutProfileData: true
        },
        {
            id: 'profile',
            title: <FormattedMessage id="profile" />,
            type: 'item',
            url: '/profile',
            icon: IconUser,
            breadcrumbs: false,
            disableWithoutProfileData: true
        },
        {
            id: 'earning',
            title: <FormattedMessage id="Earnings" />,
            type: 'item',
            url: '/earning',
            icon: IconCurrencyDollar,
            breadcrumbs: false,
            disableWithoutProfileData: true
        },
        {
            id: 'payment',
            title: <FormattedMessage id="Payment" />,
            type: 'item',
            url: '/payment',
            icon: IconCreditCard,
            breadcrumbs: false,
            disableWithoutProfileData: true
        }
    ]
};

export default other;
