import React from 'react';
import { Button } from '@mui/material';
import { IconArrowBack, IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import PropTypes from 'prop-types';

const MultiStepFormNavigation = ({ onBackClick, currentQuestion, elementRef }) => {
    const hasPrevious = useMasterSurveyResponseStore((state) => state.hasPreviousQuestion);
    const isLastQuestion = useMasterSurveyResponseStore((state) => state.isLastQuestion);

    return (
        <div
            style={{
                display: 'flex',
                marginTop: 50,
                marginLeft: 19,
                // justifyContent: 'space-between'
                justifyContent: 'left'
            }}
        >
            <Button
                variant="contained"
                disabled={!hasPrevious(currentQuestion)}
                type="button"
                onClick={onBackClick}
                sx={{ marginRight: '20px' }}
            >
                <IconArrowNarrowLeft />
            </Button>
            <Button type="submit" color="primary" variant="contained" ref={elementRef}>
                {isLastQuestion(currentQuestion) ? 'Submit' : <IconArrowNarrowRight />}
            </Button>
        </div>
    );
};

MultiStepFormNavigation.propTypes = {
    onBackClick: PropTypes.func,
    currentQuestion: PropTypes.object,
    elementRef: PropTypes.any
};

export default MultiStepFormNavigation;
