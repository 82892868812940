import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ProfileSurveyGuard from 'utils/route-guard/ProfileSurveyGuard';

// sample page routing
const RespondentEarnings = Loadable(lazy(() => import('views/RespondentEarnings')));
const SurveyList = Loadable(lazy(() => import('views/surveys/list')));
const ProfilePage = Loadable(lazy(() => import('views/loggedin-page/Profile')));
const ViewSurvey = Loadable(lazy(() => import('views/surveys/view')));
const ProfileSurvey = Loadable(lazy(() => import('views/profileSurvey')));
const Dashboard = Loadable(lazy(() => import('views/loggedin-page/Dashboard')));
const SurveySuccessPage = Loadable(lazy(() => import('views/loggedin-page/SurveySuccessPage')));
const ScreenOut = Loadable(lazy(() => import('views/sample-page/screenOut')));
const SurveyCompleted = Loadable(lazy(() => import('views/surveys/completed')));
const SurveyUnfinished = Loadable(lazy(() => import('views/surveys/unfinished')));
const Payment = Loadable(lazy(() => import('views/payment/index')));
const RequestPayout = Loadable(lazy(() => import('views/payment/RequestPayout')));
const PayoutRedirectionHandler = Loadable(lazy(() => import('views/payment/PayoutRedirectionHandler')));

// ===========================|| MAIN ROUTING ||=========================== //

const ProtectedRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'profile',
            element: <ProfilePage />
        },
        {
            path: 'earning',
            element: (
                <ProfileSurveyGuard>
                    <RespondentEarnings />
                </ProfileSurveyGuard>
            )
        },
        {
            path: 'payment',
            children: [
                {
                    path: '',
                    element: (
                        <ProfileSurveyGuard>
                            <Payment />
                        </ProfileSurveyGuard>
                    )
                },
                {
                    path: 'request-payout',
                    element: (
                        <ProfileSurveyGuard>
                            <RequestPayout />
                        </ProfileSurveyGuard>
                    )
                },
                {
                    path: 'return/:token',
                    element: (
                        <ProfileSurveyGuard>
                            <PayoutRedirectionHandler />
                        </ProfileSurveyGuard>
                    )
                }
            ]
        },
        {
            path: 'surveys',
            children: [
                {
                    path: '',
                    element: (
                        <ProfileSurveyGuard>
                            <SurveyList />
                        </ProfileSurveyGuard>
                    )
                },
                {
                    path: 'completed',
                    element: (
                        <ProfileSurveyGuard>
                            <SurveyCompleted />
                        </ProfileSurveyGuard>
                    )
                },
                {
                    path: 'unfinished',
                    element: (
                        <ProfileSurveyGuard>
                            <SurveyUnfinished />
                        </ProfileSurveyGuard>
                    )
                },
                {
                    path: ':surveyToken/view/question/:qId',
                    element: <ViewSurvey />
                },
                {
                    path: ':surveyToken/view/screenout',
                    element: (
                        <ProfileSurveyGuard>
                            <ScreenOut />
                        </ProfileSurveyGuard>
                    )
                }
            ]
        }
    ]
};

export default ProtectedRoutes;
