// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';

// third party
import { useTimer } from 'react-timer-hook';

import { gridSpacing } from 'store/constant';

// assets
import imageGrid from 'assets/images/maintenance/img-soon-grid.svg';
import imageDarkGrid from 'assets/images/maintenance/img-soon-grid-dark.svg';
import imageBlock from 'assets/images/maintenance/img-soon-block.svg';
import imageBlueBlock from 'assets/images/maintenance/img-soon-blue-block.svg';
import imagePurpleBlock from 'assets/images/maintenance/img-soon-purple-block.svg';

// styles
const CardMediaWrapper = styled('div')({
    maxWidth: 720,
    margin: '0 auto',
    position: 'relative'
});

const PageContentWrapper = styled('div')({
    maxWidth: 450,
    margin: '0 auto',
    textAlign: 'center'
});

const TimerWrapper = styled('div')({
    maxWidth: 450,
    margin: '0 auto'
});

const ComingSoonCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

const TimeBlock = styled('div')(({ theme }) => ({
    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
    color: theme.palette.mode === 'dark' ? theme.palette.dark.light : theme.palette.secondary.main,
    borderRadius: '12px',
    padding: '24px 0',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '3rem'
}));

const CardMediaBlock = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '8s blink ease-in-out infinite'
});

const CardMediaBlue = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '15s wings ease-in-out infinite'
});

const CardMediaPurple = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '12s wings ease-in-out infinite'
});

// ===========================|| COMING SOON 2 ||=========================== //

const Privacy = () => {
    const theme = useTheme();
    const time = new Date();
    time.setSeconds(time.getSeconds() + 3600 * 24 * 2 - 3600 * 15.5);

    const { seconds, minutes, hours, days } = useTimer({ expiryTimestamp: time });

    return (
        <ComingSoonCard>
            <CardContent>
                <Grid container justifyContent="center" spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <PageContentWrapper>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography variant="h1">Privacy Policy</Typography>
                                </Grid>
                            </Grid>
                        </PageContentWrapper>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <section>
                            <h2>Introduction</h2>
                            <p>
                                FlipSurvey.com (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) is committed to protecting your privacy.
                                This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information. By
                                accessing or using FlipSurvey.com, you agree to the terms outlined in this Privacy Policy.
                            </p>
                        </section>

                        <section>
                            <h2>Information We Collect</h2>
                            <ol>
                                <li>
                                    <strong>User-Provided Information:</strong> When you register for an account or use our services, we may
                                    collect personal information such as your name, email address, and other details you voluntarily
                                    provide.
                                </li>
                                <li>
                                    <strong>Survey Responses:</strong> We collect and store the responses you submit while using
                                    FlipSurvey.com. These responses are considered confidential and are only accessible by the account
                                    owner.
                                </li>
                                <li>
                                    <strong>Automatically Collected Information:</strong> We may collect certain information automatically,
                                    including but not limited to your IP address, device type, browser type, and usage data. This
                                    information helps us improve our services and enhance user experience.
                                </li>
                            </ol>
                        </section>

                        <section>
                            <h2>How We Use Your Information</h2>
                            <ol>
                                <li>
                                    <strong>To Provide Services:</strong> We use your personal information to provide you with access to and
                                    use of FlipSurvey.com, including creating and managing your account, processing survey responses, and
                                    offering customer support.
                                </li>
                                <li>
                                    <strong>To Improve Our Services:</strong> We analyze usage patterns and feedback to enhance our
                                    services, troubleshoot issues, and develop new features.
                                </li>
                                <li>
                                    <strong>Communication:</strong> We may use your contact information to communicate with you about your
                                    account, service updates, promotions, and important announcements. You can opt-out of promotional
                                    communications at any time.
                                </li>
                            </ol>
                        </section>

                        <section>
                            <h2>Information Sharing and Disclosure</h2>
                            <ol>
                                <li>
                                    <strong>Third-Party Service Providers:</strong> We may share your information with trusted third-party
                                    service providers to assist us in providing, maintaining, and improving our services. These providers
                                    are obligated to protect your information.
                                </li>
                                <li>
                                    <strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to
                                    a valid legal request, such as a court order, government inquiry, or law enforcement agency.
                                </li>
                            </ol>
                        </section>

                        <section>
                            <h2>Data Security</h2>
                            <p>
                                We take reasonable measures to protect your personal information from unauthorized access, disclosure,
                                alteration, and destruction. However, no online platform can guarantee absolute security. Users are
                                encouraged to take steps to protect their account credentials and report any suspicious activities.
                            </p>
                        </section>

                        <section>
                            <h2>Your Choices</h2>
                            <p>
                                You have the right to access, correct, update, or delete your personal information. You can manage your
                                account settings or contact us for assistance. You may choose to opt-out of promotional communications at
                                any time.
                            </p>
                        </section>

                        <section>
                            <h2>Changes to this Privacy Policy</h2>
                            <p>
                                We may update this Privacy Policy periodically to reflect changes in our practices. The revised policy will
                                be effective immediately upon posting. Please review this page regularly to stay informed about our privacy
                                practices.
                            </p>
                        </section>

                        <section>
                            <h2>Contact Us</h2>
                            <p>
                                If you have questions, concerns, or requests regarding this Privacy Policy, please contact us at{' '}
                                <a href="mailto:contact@flipsurvey.com">contact@flipsurvey.com</a>.
                            </p>
                        </section>

                        <footer>
                            <p>Thank you for choosing FlipSurvey.com. Your privacy is important to us.</p>
                        </footer>
                    </Grid>
                </Grid>
            </CardContent>
        </ComingSoonCard>
    );
};

export default Privacy;
