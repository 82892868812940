import React, { useEffect, useRef } from 'react';
import { Typography } from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import SurveyServices from 'services/survey.service';
import { useParams } from 'react-router-dom';

const BrowsershotSurvey = () => {
    const { surveyToken } = useParams();
    const [survey, setSurvey] = React.useState({});

    const fetchSurveyinfo = async () => {
        const { data } = await SurveyServices.fetchSurveyInfo(surveyToken);
        setSurvey(data);
    };

    useEffect(() => {
        fetchSurveyinfo();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <MainCard title={survey?.survey_title ?? ''} sx={{ marginTop: '50px' }}>
            <Typography variant="body"> {survey?.survey_description ?? ''} </Typography>
        </MainCard>
    );
};

export default BrowsershotSurvey;
