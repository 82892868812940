// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 * */
import logoDark from 'assets/images/surveyFlip-logo.svg';
import logo from 'assets/images/surveyFlip-logo.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         *
         *
         */
        <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="110" />
    );
};

export default Logo;
