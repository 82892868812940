import PropTypes from 'prop-types';
import { intersection } from 'lodash';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Grid } from '@mui/material';
import transformer from './transformChoice';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels, ArcElement);

const ChartWrapper = ({ choiceData, questionData, toggle }) => {
    const [labels, values, percentage] = transformer(choiceData, questionData);
    const COLORS = ['#d1e5f0', '#92c5de', '#4393c3', '#2166ac', '#053061', '#67001f', '#b2182b', '#d6604d', '#f4a582', '#fddbc7'];
    const labelColors = {}; // colors used for each label

    const mapColorsToLabels = (labels) => {
        const usedKeys = intersection(Object.keys(labelColors), labels);
        let firstAvailColor = usedKeys.length; // sensible place to start looking for new colors

        const chartColors = [];
        const usedColors = {};

        // get previously used colors for all labels in current report
        usedKeys.forEach((label) => {
            usedColors[labelColors[label]] = true;
        });

        labels.forEach((label) => {
            // if we've never seen this label before
            if (!labelColors[label]) {
                while (usedColors[COLORS[firstAvailColor]]) {
                    // if we are already using this color, get the next color
                    firstAvailColor += 1;
                }
                // if we are not already using this color, save it
                labelColors[label] = COLORS[firstAvailColor];
                firstAvailColor += 1;
            }

            chartColors.push(labelColors[label]);
        });

        return chartColors;
    };

    const pieOptions = {
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
            legend: {
                position: 'top',
                align: 'center',
                labels: {
                    font: { size: 16 }
                }
            }
        }
    };
    const options = {
        indexAxis: 'y',
        // elements: {
        //     bar: {
        //         borderWidth: 1,
        //         borderRadius: '10px' // Adjust the radius value as needed
        //     }
        // },
        barRoundness: 1,
        layout: {
            padding: {
                // left: 50,
                right: 50
                // top: 100,
                // bottom: 0
            }
        },
        responsive: true,
        plugins: {
            // Change options for ALL labels of THIS CHART
            // legend: {
            //     onClick: (event, legendItem, legend) => {
            //         const index = legend.chart.data.labels.indexOf(legendItem.text);
            //         legend.chart.toggleDataVisibility(index);
            //         legend.chart.update();
            //     },
            //     labels: {
            //         generateLabels: (chart) => {
            //             const visibility = [];
            //             for (let i = 0; i < chart.data.labels.length; i += 1) {
            //                 if (chart.getDataVisibility(i) === true) {
            //                     visibility.push(false);
            //                 } else {
            //                     visibility.push(true);
            //                 }
            //             }
            //             return chart.data.labels.map((label, index) => ({
            //                 text: label,
            //                 strokeStyle: chart.data.datasets[0].backgroundColor[index],
            //                 fillStyle: chart.data.datasets[0].backgroundColor[index],
            //                 hidden: visibility[index]
            //             }));
            //         },
            //         font: { size: 16 }
            //     }
            // }
            legend: false
        },
        scales: {
            x: {
                display: false,
                grid: {
                    drawBorder: false,
                    lineWidth: 0.5,
                    display: false
                },

                ticks: {
                    display: false
                }
            },
            y: {
                display: false,
                grid: {
                    display: false,
                    drawBorder: false,
                    lineWidth: 0.5
                },
                ticks: {
                    display: false,
                    mirror: true
                    // labelOffset: -45
                }
            }
        }
    };
    const barData = {
        labels,
        datasets: [
            {
                barPercentage: 0.9,
                categoryPercentage: 1.0,
                data: values,
                percentage,
                backgroundColor: mapColorsToLabels(labels),
                borderRadius: '100px',
                datalabels: {
                    labels: {
                        // index: {
                        //     color: '#000000',
                        //     anchor: 'top',
                        //     align: 'center',
                        //     formatter(value, ctx) {
                        //         return ctx.chart.data.labels[ctx.dataIndex];
                        //     },
                        //     padding: 2
                        // },
                        value: {
                            color: '#222',
                            anchor: 'end',
                            align: 'right',
                            font: {
                                // weight: 'bolder',
                                size: '16px'
                            },
                            formatter(value, ctx) {
                                return `${Math.ceil(ctx.dataset.percentage[ctx.dataIndex] * 100)}%`;

                                // return value;
                                // return ctx.dataset.data[ctx.dataIndex];
                            }
                        },
                        name: {
                            color: '#222',
                            font: {
                                // weight: 'bolder',
                                size: '16px'
                            },
                            anchor: 'start',
                            align: 'end',
                            formatter(value, ctx) {
                                return ctx.chart.data.labels[ctx.dataIndex];
                            }
                        }
                    }
                }
            }
        ]
    };

    const height = Object.keys(labels).length * 8 + 16;

    return (
        <Grid container direction="column">
            {!toggle && (
                <Grid item>
                    <Bar data={barData} options={options} height={height} />
                </Grid>
            )}
        </Grid>
    );
};

ChartWrapper.propTypes = {
    choiceData: PropTypes.object,
    questionData: PropTypes.object,
    toggle: PropTypes.bool
};
export default ChartWrapper;
