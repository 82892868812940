/* eslint-disable class-methods-use-this */
import axiosServices from 'utils/axios';

class AnswerService {
    async create(data) {
        const response = await axiosServices.post('answers/create', data);
        return response;
    }

    async createSurveyProfileData(data) {
        const response = await axiosServices.post('answers/create-survey-profile-data', data);
        return response;
    }

    async profileSurveyAnswer() {
        const response = await axiosServices.get('answers/profile-survey-answer');
        return response;
    }

    async surveyStarted(surveyToken) {
        const response = await axiosServices.get(`answers/${surveyToken}/survey-started`);
        return response;
    }
}

export default new AnswerService();
