import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import { isUndefined } from 'lodash';

// third-party

// reducer - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

import { API_BASE } from 'config';
// project imports
import axios from 'utils/axios';
import Loader from 'ui-component/Loader';
import useProfileStore from 'zustand_store/useProfileStore';
import ProfileService from 'services/profile.service';
import dayjs from 'dayjs';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

axios.defaults.baseURL = API_BASE;

// const verifyToken = (serviceToken) => {
//     if (!serviceToken) {
//         return false;
//     }
//     const decoded = jwtDecode(serviceToken);
//     return decoded.exp > Date.now() / 1000;
// };

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ===========================|| JWT CONTEXT & PROVIDER ||=========================== //

const JWTContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    // eslint-disable-next-line prettier/prettier
    logout: () => {}
});

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const SET_PROFILE_SURVEY_COMPLETION_FOR_CURRENT_USER = useProfileStore((state) =>
        isUndefined(state) ? () => {} : state.SET_PROFILE_SURVEY_COMPLETION_FOR_CURRENT_USER
    );
    const isProfileSurveyCompleted = useProfileStore((state) => (isUndefined(state) ? () => {} : state.isProfileSurveyCompleted));

    const login = async (email, password) => {
        const response = await axios.post('/login', {
            email,
            password,
            role: 'Respondent'
        });
        // console.log(response);
        const serviceToken = response.data.token;
        setSession(serviceToken);

        const { data } = await axios.get('/profile');

        // Update last login for login respondent
        await ProfileService.update({
            last_login: dayjs(new Date()).format('YYYY-MM-DD HH:hh:mm')
        });

        const user = data;

        // SET_PROFILE_SURVEY_COMPLETION_FOR_CURRENT_USER(user?.meta?.profile_answer_completed);
        SET_PROFILE_SURVEY_COMPLETION_FOR_CURRENT_USER(100);
        isProfileSurveyCompleted();

        // localStorage.setItem('serviceToken', serviceToken);
        dispatch({
            type: LOGIN,
            payload: {
                user
            }
        });
    };

    const logout = () => {
        setSession(null);
        localStorage.removeItem('responses');
        dispatch({ type: LOGOUT });
    };

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken) {
                    setSession(serviceToken);
                    const response = await axios.get('/profile');
                    const user = response.data;
                    // SET_PROFILE_SURVEY_COMPLETION_FOR_CURRENT_USER(user?.meta?.profile_answer_completed);
                    SET_PROFILE_SURVEY_COMPLETION_FOR_CURRENT_USER(100);
                    isProfileSurveyCompleted();

                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });
                } else {
                    dispatch({
                        type: ACCOUNT_INITIALIZE,
                        payload: {
                            isLoggedIn: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                dispatch({
                    type: ACCOUNT_INITIALIZE,
                    payload: {
                        isLoggedIn: false,
                        user: null
                    }
                });
            }
        };

        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!state.isInitialized) {
        return <Loader />;
    }

    return <JWTContext.Provider value={{ ...state, login, logout }}>{children}</JWTContext.Provider>;
};

JWTProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default JWTContext;
