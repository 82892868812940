import React, { useEffect } from 'react';
import { useNavigate, Link as RouterLink, useParams } from 'react-router-dom';

// material-ui
import { Box, Button, Card, Grid, Typography, useMediaQuery } from '@mui/material';
import AlertTitle from '@mui/material/AlertTitle';

// project imports
import AuthCardWrapper from '../AuthCardWrapper';
import AuthWrapper1 from '../AuthWrapper1';

import Logo from 'ui-component/Logo';
import { useTheme } from '@mui/material/styles';
import AuthFooter from 'ui-component/cards/AuthFooter';
import useMasterSurveyResponseStore from 'zustand_store/useMasterSurveyResponseStore';
import SurveyService from 'services/survey.service';
import AnimateButton from 'ui-component/extended/AnimateButton';
import ChartWrapper from './ChartWrapper';
import ListSkeleton from 'ui-component/custom/ListSkeleton';
import { margin } from '@mui/system';

// assets

// ==============================|| AUTH3 - CHECK MAIL ||============================== //

const SurveyResults = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
    const [score, setScore] = React.useState(null);
    const [survey, setSurvey] = React.useState({});
    const [isSurveyDataLoaded, setIsSurveyDataLoaded] = React.useState(false);
    const [response, setResponse] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const { shortcode } = useParams();

    const fetchResponses = async (survey) => {
        const resp = await SurveyService.getGraphResponse(survey.survey_token);
        setResponse(resp.data.data);
    };

    const handleSurveyScoreOnAssessment = async () => {
        const { data } = await SurveyService.getSurveyFromShortCode(shortcode);
        setSurvey(data);
        fetchResponses(data);
        setIsLoading(false);
        setIsSurveyDataLoaded(true);
    };

    useEffect(() => {
        handleSurveyScoreOnAssessment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //     // if (survey?.meta) {
    //     //     setIsSurveyDataLoaded(true);
    //     // }
    //     setTimeout(() => {
    //         if (survey?.meta?.redirection_url) {
    //             window.location.href = survey?.meta?.redirection_url;
    //         }
    //     }, 3000);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [survey]);

    return (
        <Box sx={{ maxWidth: '720px', margin: '0 auto', minHeight: 'calc(100vh - 68px)' }}>
            <Card sx={{ margin: '50px 0', textAlign: 'center' }}>
                <RouterLink to="#">
                    <Logo />
                </RouterLink>
            </Card>
            <Card>
                <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'} mb={5} textAlign="center">
                    The survey time is over, here is the result:
                </Typography>
                {isSurveyDataLoaded &&
                    response &&
                    response.choices_report_data &&
                    Object.keys(response.choices_report_data).map((element, index) => (
                        <Grid container direction="row" mb={4} index={index}>
                            <Grid item xs={12}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="h4" align="left">
                                            {`${index + 1}.${response?.questions.find((e) => e.id === Number(element)).question_title}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item mt={4}>
                                        <ChartWrapper
                                            choiceData={response?.choices_report_data[element]}
                                            questionData={response?.questions.find((e) => e.id === Number(element))}
                                            toggle={false}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                {isSurveyDataLoaded && response && response.choices_report_data ? (
                    <Typography variant="h4">{response?.total_votes ?? 0} Vote(s) [Final Result]</Typography>
                ) : (
                    <ListSkeleton times={10} />
                )}
            </Card>
        </Box>
    );
};

export default SurveyResults;
