// material-ui
import { Typography, Button, Grid, Alert, AlertTitle } from '@mui/material';
// project imports
import { useTheme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import AuthCardWrapper from '../AuthCardWrapper';
import AnimateButton from 'ui-component/extended/AnimateButton';
import AuthWrapper1 from '../AuthWrapper1';

// ==============================|| Sorry Page PAGE ||============================== //

const GenericSurveyError = () => {
    const theme = useTheme();
    const { state } = useLocation();

    return (
        <AuthWrapper1>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh', backgroundColor: '#ecf0f1' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 10px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item xs={12}>
                                        <Grid container alignItems="center" justifyContent="center" textAlign="center" spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography color={theme.palette.secondary.main} gutterBottom variant="h3">
                                                    Oops
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} py={3}>
                                                <Typography variant="caption" fontSize="17px" textAlign="center" sx={{ color: 'red' }}>
                                                    {state.msg ?? 'Sorry! Not found.'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AnimateButton>
                                                    <Button
                                                        disableElevation
                                                        href="/"
                                                        size="large"
                                                        type="submit"
                                                        variant="contained"
                                                        color="secondary"
                                                    >
                                                        Go Home
                                                    </Button>
                                                </AnimateButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AuthWrapper1>
    );
};

// GenericSurveyError.propTypes = {
//     code: PropTypes.string,
//     msg: PropTypes.string
// };

export default GenericSurveyError;
