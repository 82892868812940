import React, { lazy } from 'react';
// project imports
import MinimalLayout from 'layout/MinimalLayout';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

const CheckRole = Loadable(lazy(() => import('views/loggedin-page/CheckRole')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MinimalLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'check-role',
            element: <CheckRole />
        }
    ]
};

export default AuthenticationRoutes;
