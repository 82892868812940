import create from 'zustand';

const useProfileStore = create((set) => ({
    profileSurveyCompletion: 0,
    benchmarkProfileSurvey: 99,
    completedProfileSurvey: false,
    SET_PROFILE_SURVEY_COMPLETION_FOR_CURRENT_USER: (score) => {
        set((state) => {
            state.profileSurveyCompletion = score;
        });
    },
    isProfileSurveyCompleted: () =>
        set((state) => {
            if (state.profileSurveyCompletion > state.benchmarkProfileSurvey) {
                state.completedProfileSurvey = true;
            } else {
                state.completedProfileSurvey = false;
            }
        })
}));

export default useProfileStore;
